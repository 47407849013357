@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #222222;
  --first-color-light: #848484;
  --white-color: #ffffff;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --blue-color: #0f70cd;
  --lblue-color: #f2fafc;
}

body {
  font-family: "Poppins", sans-serif !important;
  /* background-color: #E5E5E5 !important; */
  background: linear-gradient(to right, #e0e0ff, #ffffff);
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  /* background-color: white; */
  background: linear-gradient(to right, #e0e0ff, #ffffff);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

body.login-bg {
  padding: 0 !important;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.content-bg-image {
  height: calc(100vh - 96px);
  /* background-image: url('./../images/admin-panel-bg.jpg'); */
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 230px;
}

.main-title {
  margin-top: 70px;
}

.login-form {
  /* border: 1px solid lightgrey; */
  border-radius: 10px;
  box-shadow: 1px 1px 10px #349fdd;
  background-color: #fff;
}

.primary-bg {
  background-color: #4652c4 !important;
}

.secondary-bg {
  background-color: #dfe0ff !important;
}

.text-color {
  color: #fec52e;
}

.custom-bg-btn {
  background-color: #0753a2;
  border-radius: 10px;
  height: 36px;
  margin-top: 4px;
  padding: 6px 27px !important;
}

.inside-bg {
  /* background           : url("../images/inside-bg.jpg") no-repeat bottom center; */
  background-size: cover;
  background-attachment: fixed;
}

.navbar .navbar-toggler {
  border: none !important;
  padding: 0 !important;
  top: 1rem !important;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  /* background-color: var(--white-color); */
  background: linear-gradient(to right, #e0e0ff, #ffffff);
  padding: 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
  box-shadow: 0 0px 10px #333333;
}

.nav_sublist {
  background-color: #f9f9f9;
  display: none;
}

.nav-element {
  background: white;
  color: #000 !important;
}

.nav-element.active {
  background: linear-gradient(to right, #e0e0ff, #ffffff);
  color: #000 !important;
}

.nav_element.collapsed .nav_sublist {
  animation: slide-down 0.3s ease;
  display: block;
}

.nav_sublink {
  color: #555;
  display: block;
  padding: 10px 25px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.collapsed .arrow-icon {
  transform: rotate(90deg);
}

.body-pd .nav-icon {
  padding-right: 0.5rem;
  transition: 0.5s;
}

.l-navbar.show {
  left: 0;
}

.body-pd .nav_name {
  padding-left: 10px;
}

.inside-bg .l-navbar:hover .nav_name {
  padding-left: 0;
}

header.body-pd .nav_logo {
  display: none;
}

.body-pd .l-navbar:hover .nav_logo {
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-text-color {
  color: #1f1f1f;
  font-weight: 600;
}

.custom-background-color {
  background-color: #fff;
  box-shadow: 0px 2px 5px #27c87a;
}

.custom-cancel-btn {
  background-color: #eef5ff;
}

.sidemenu-item .active {
  background-color: #fec52e;
  color: white !important;
}

li a:hover {
  background-color: transparent;
  color: #aaa !important;
}

.breadcum-text-color {
  color: #074795 !important;
  font-weight: bold;
}

.sidebar {
  height: calc(100vh - 85px);
  border-right: 1px solid #07479542;
}

.sidebar li {
  list-style-type: none;
  border-bottom: 1px solid #07479542;
}

a.active li {
  background-color: #074795;
  color: #fff;
}

.sidebar li:hover,
.sidebar_settings li:hover {
  background-color: #074795;
  color: #fff;
}

.sidebar li.active {
  background-color: #fec52e;
  color: white;
}

.nav-list li a.active {
  background-color: #fec52e;
  color: white;
}

.nav-link.active {
  background-color: #fec52e;
  color: white;
}

.nav-item:hover {
  background-color: white !important;
  color: black !important;
}

.content-scroll {
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.table_new_ui th {
  border: none;
}

.table_new_ui th {
  background-color: #32b8cb !important;
  color: #fff !important;
}

.breadcrumb > li + li:before {
  content: "" !important;
  text-decoration: none !important;
}

.breadcrumb:hover {
  background-color: transparent !important;
  color: #333333 !important;
}

.breadcrumb {
  padding: 10px 16px;
  font-size: 14px;
  color: #aaa !important;
  letter-spacing: 2px;
  border-radius: 5px !important;
}

.active > .page-link,
.page-link.active {
  background-color: #4652c4 !important;
  color: white !important;
}

.disabled {
  background-color: #eef5ff !important;
  color: black !important;
}

.search-position {
  position: absolute;
  top: -4px;
  left: 85%;
  padding: 6.5px 5px;
  cursor: pointer;
}

.search-position i.bi {
  font-size: 18px;
}

.active > .page-link,
.page-link.active {
  border-color: #4652c4 !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.nav-tabs .nav-link {
  /* color: #fff; */
  /* border-color: #E98600 !important; */
  border-color: #4652c4 !important;
  /* padding: 8px 18px; */
  padding: 4px 15px;
  font-size: 14px;
}

.nav-tabs .nav-link:hover {
  /* background-color: #000 !important;
  color: #fec52e !important; */
  background-color: #4652c4 !important;
  color: #fff !important;
}

.nav-tabs .nav-link.active {
  /* background-color: #000 !important;
  color: #fec52e !important; */
  background-color: #4652c4 !important;
  color: #fff !important;
}

.nav-tabs .nav-link.active:hover {
  /* border-bottom: 2px solid #074795 !important; */
  color: white !important;
}

.nav-tabs {
  border-bottom: var(--bs-nav-tabs-border-width) solid #4652c4 !important;
}

.modal-box-shadow {
  box-shadow: 1px 1px 10px black;
}

.cursor-pointer {
  cursor: pointer;
}

.custom_link {
  cursor: pointer;
  color: #0d6efd;
  font-weight: 700;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-2gftvx.Mui-selected {
  background-color: #32b8cb !important;
  color: #fff !important;
}

.modal-show {
  display: block !important;
}

.border-primary-color {
  border-color: #fec52e !important;
}

.modal-hide {
  display: none !important;
}

.modal-bg {
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: 1px 1px 10px black;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.modal-custom {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000ab !important;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex) !important;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg) !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.login_logo {
  height: 20px;
  top: -100px;
  position: relative;
}

.login_logo img {
  height: 160px;
}

.custom_spinner {
  /* position: absolute;
  top: 60px;
  left: 220px;
  height:calc(100vh) !important;
  width: calc(100vw - 245px) !important;
  background: #00000075;
  z-index: 25; */
  position: fixed;
  top: 0;
  left: 220px;
  height: 100vh;
  width: calc(100vw - 245px) !important;
  background: #00000075;
  z-index: 25;
}

.custom_spinner .spinner-border {
  position: absolute;
  top: 50%;
  color: white;
}

.main-content {
  position: relative;
}

@media (max-width: 768px) {
  .custom_spinner {
    position: absolute;
    top: 60px;
    left: 0px;
    height: 100vh;
    width: calc(100vw - 0px) !important;
    background: #000000bf;
    z-index: 25;
  }

  .login_logo {
    height: 20px;
    top: -60px;
    position: relative;
  }

  .login_logo img {
    height: 50px;
  }
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  color: #fff;
  background-color: #074795;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  height: 36px;
  padding: 6px 27px !important;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

@media (min-width: 768px) {
  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 0 0 245px;
    background-color: #32b8cb !important;
  }

  .l-navbar {
    left: 0;
    width: 224px;
    /* background: linear-gradient(to right, rgba(8, 155, 171, 1) 0%, rgba(13, 181, 200, 1) 100%); */
    background-color: white !important;
  }

  .nav_logo,
  .body-pd .l-navbar:hover .nav_logo {
    background-color: #32b8cb;
  }

  .body-pd .nav_logo {
    padding-left: 0.5rem;
    padding-left: 0.8rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 0px);
  }

  .l-navbar:hover {
    width: calc(var(--nav-width) + 156px) !important;
  }

  .l-navbar:hover .nav_logo-icon {
    /* background     : url("../images/logo.png") no-repeat; */
    display: block;
    width: 121px;
    height: 48px;
    background-size: 100%;
    transition: 0.5s;
  }

  .body-pd .l-navbar:hover .nav-icon {
    padding-right: 0;
    transition: 0.5s;
  }

  .body-pd .nav_logo-icon {
    width: 40px;
    height: 40px;
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 15px);
  }
}

@media (max-width: 767.98px) {
  .header {
    height: 65px;
  }

  header.body-pd {
    padding-left: 235px;
  }

  .l-navbar {
    width: 225px;
    left: -235px;
  }

  .l-navbar.show {
    left: 0;
  }

  .body-pd .nav_logo-icon {
    /* background     : url("../images/logo.png") no-repeat; */
    width: 121px;
    height: 48px;
    background-size: 100%;
    margin-left: 8px;
  }

  .body-pd .nav_name {
    padding-left: 0;
  }

  .main-title {
    margin-top: 75px;
    /* padding-left: 15px; */
  }

  .login-logo {
    width: 150px;
  }

  .login-row {
    margin: 0;
  }

  .sidebar {
    top: 6.75rem;
  }

  .nav_logo,
  .nav_link {
    padding-top: 0.55rem;
  }
}

body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 1.8 !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #a09e9e !important;
  background: #eff7f8 !important;
}

body {
  color: #333333 !important;
  font-family: "Poppins", sans-serif !important;
  position: relative;
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

.login-section {
  flex: 1;
  padding: 20px;
  height: 300px;
}

.login-form {
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

button:hover {
  background-color: #4652c4;
}

.login-bg-section {
  background-color: #f0f7f8;
}

.logo-image {
  width: 84px;
  height: 84px;
  border-radius: 52px;
  object-fit: cover;
}

.user-profile {
  height: 35px;
  width: 35px;
  border: 1px solid #c1c1c1;
  padding: 2px;
  border-radius: 20px;
}

.bell-icon {
  height: 25px !important;
  width: 25px !important;
}

.main-logo {
  width: 170px;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body.login-bg {
  padding: 0 !important;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.breadcrumb {
  font-size: 16px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--white-color);
  padding: 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
  box-shadow: 0 0px 10px #333333;
}

.navinstitute {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* background: linear-gradient(to right, rgba(8, 155, 171, 1) 0%, rgba(13, 181, 200, 1) 100%); */
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 0rem;
  padding-left: 5rem;
  /* background-color: white; */
  /* background: #32B8CB !important; */
  border-bottom: solid 1px white;
}

.nav_logo-icon {
  /* background     : url("../images/logo.png") no-repeat; */
  display: block;
  width: 121px;
  height: 48px;
  background-size: 100%;
  transition: 0.5s;
}

.body-pd .nav_logo-icon {
  /* background     : url("../images/logo-small.png") no-repeat; */
  display: block;
  width: 32px;
  height: 32px;
  background-size: 100%;
  transition: 0.5s;
}

.nav_logo-name {
  color: var(--first-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: black;
  transition: 0.3s;
  font-size: 17px;
  white-space: nowrap;
  display: block;
  position: relative;
  padding: 15px;
  line-height: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-transform: capitalize;
  border-bottom: solid 1px white;
}

.nav_link:hover {
  color: black;
  background-color: white;
}

.nav-icon {
  font-size: 1.25rem;
  width: 18px;
  height: 18px;
  display: inline-block;
}

.body-pd .nav-icon {
  padding-right: 0.5rem;
  transition: 0.5s;
}

.l-navbar.show {
  left: 0;
}

.active {
  color: #4652c4;
  text-decoration: none;
}

.nav-element.active {
  color: #000;
  background: #e0e0ff;
}

.nav-element:hover {
  color: #000;
  background: #e0e0ff;
  /* color: #000;
  background: #fec52e; */
}

.height-100 {
  height: 100vh;
}

a {
  text-decoration: none;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875em;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f24 {
  font-size: 1.5rem;
}

.btn-primary {
  background-color: #4652c4 !important;
  color: #fff !important;
  border: none !important;
}

.bg_yellow {
  /* background-color: #fec52e !important; */
  background: linear-gradient(to right, #e0e0ff, #ffffff);
}

.btn-black {
  background-color: #4652c4 !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: #4652c4 !important;
  color: #fff !important;
}

.border-bottom-primary {
  border-bottom: 1px solid #4652c4 !important;
}

.border-top-primary {
  border-top: 1px solid #4652c4 !important;
}

.inside-bg {
  /* background           : url("../images/inside-bg.jpg") no-repeat bottom center; */
  background-size: cover;
  background-attachment: fixed;
}

.navbar .navbar-toggler {
  border: none !important;
  padding: 0 !important;
  top: 1rem !important;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  /* background-color: #222222 !important; */
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav {
  margin: 0 10px;
}

.sidebar .nav-link {
  font-weight: 300;
  color: #f2f2f2;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.custom-box-shadow {
  box-shadow: 0 0.5rem 1rem #0000004a !important;
}

.sidebar .nav-link.active,
.sidebar .nav-link.active:focus,
.sidebar .nav-link.active:hover {
  color: #ffffff;
  background-color: #0f70cd;
}

.sidebar .nav-link:focus,
.sidebar .nav-link:hover {
  color: #f07d32;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar {
  background-color: #f2f2f2;
}

.navbar-brand {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  background-color: #f2f2f2 !important;
  text-align: center;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.15rem 1rem;
}

.navbar .form-control:focus {
  box-shadow: none;
}

.nav_name {
  transition: 0.5s;
}

.body-pd .nav_name {
  padding-left: 10px;
}

.inside-bg .l-navbar:hover .nav_name {
  padding-left: 0;
}

.logo {
  width: 145px;
}

.breadcrumb-hd .breadcrumb-item,
.breadcrumb-hd .breadcrumb-item a {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-decoration: none;
}

.table th {
  /* background-color: #0753a2 !important;
  color: white  !important; */
  background-color: #4652c4 !important;
  color: #fff !important;
}

.table_new_ui th {
  border: none;
}

.table_new_ui th {
  background-color: #cccccc;
}

.table_new_ui th,
.table_new_ui td {
  font-size: 14px;
  color: #333333;
  font-weight: 400;
}

header.body-pd .nav_logo {
  display: none;
}

.body-pd .l-navbar:hover .nav_logo {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .login-block-wraper {
    min-height: calc(100vh - 40px);
  }

  .login-block {
    width: 600px;
  }

  .login-fileds {
    border-left: 1px solid #b3b3b3;
    border-top: none;
    margin-top: 0;
  }

  .login-elements .login-elements-icons {
    width: 250px;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 0 0 245px;
    /* background-color: #fec52e !important; */
    background: linear-gradient(to right, #e0e0ff, #ffffff);
  }

  .l-navbar {
    left: 0;
    width: 224px;
    /* background-color: white !important; */
    background: linear-gradient(to right, #e0e0ff, #ffffff);
  }

  .nav_logo,
  .body-pd .l-navbar:hover .nav_logo {
    /* background-color: #fec52e; */
    background: linear-gradient(to right, #e0e0ff, #ffffff);
  }

  .body-pd .nav_logo {
    padding-left: 0.5rem;
    padding-left: 0.8rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 0px);
  }

  .l-navbar:hover {
    width: calc(var(--nav-width) + 156px) !important;
  }

  .l-navbar:hover .nav_logo-icon {
    display: block;
    width: 121px;
    height: 48px;
    background-size: 100%;
    transition: 0.5s;
  }

  .body-pd .l-navbar:hover .nav-icon {
    padding-right: 0;
    transition: 0.5s;
  }

  .body-pd .nav_logo-icon {
    width: 40px;
    height: 40px;
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 15px);
  }
}

@media (min-width: 992px) {
  .navbar {
    background-color: #f2f2f2;
  }

  .score-card-sticky {
    position: sticky;
    top: 160px;
  }

  .navbar-brand {
    position: relative;
  }

  .navbar-brand:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 10px;
    right: 10px;
    border-bottom: 1px solid #f4715c;
  }

  .sidebar,
  .navbar-brand {
    width: 250px;
  }

  .sidebar {
    padding: 70px 0 0;
  }

  .content-waper {
    padding-left: 265px;
    padding-top: 0;
  }

  .global-search {
    width: 450px;
  }

  .signup-quotblock {
    border-right: 1px solid #cccccc;
  }

  .changepass-row {
    min-height: calc(100vh - 220px);
  }

  .deatils-block .list li {
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .login-block.lg {
    width: 1140px;
    margin-top: 100px;
  }

  .signup-block {
    margin-top: 100px;
  }
}

@keyframes animate-bar {
  0% {
    width: 0;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.content-bg-color {
  background: #ffffff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  border: none;
  -webkit-box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
  box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
  padding: 15px 30px !important;
}

.bg-flat-color {
  border-radius: 19px;
  box-shadow: 0 1px 8px -1px #000 !important;
}

@media (max-width: 767.99px) {
  .main-logo {
    width: 75px !important;
  }

  .content-bg-image {
    padding-left: 0px !important;
  }
}

.nav_list {
  background: white;
  height: calc(100vh - 60px) !important;
  overflow-y: auto !important;
  padding-bottom: 10px !important;
}

.header-bottom {
  border-bottom: solid 1px #cccccc;
}

.disabled-link {
  color: rgb(172, 167, 167) !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  background-color: transparent !important;
  content: "" !important
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item a:hover {
  background-color: transparent !important;
  color: #333333 !important;
}

.breadcrumb-item a.active:hover {
  background-color: transparent !important;
  color: #0753a2 !important;
}

.custom-link {
  color: #089bab !important;
}

.service-list {
  /* border: 1px solid #32B8CB !important; */
  min-height: 130px !important;
  border-radius: 8px !important;
  /* background-color: white !important; */
  text-decoration: none !important;
  transition: 0.5s !important;
}

.service-list:hover,
.service-list:focus {
  box-shadow: 1px 1px 8px white !important;
  transform: scale(1.1) !important;
}

.service-list h3 {
  font-size: 24px;
  color: black !important;
}

.service-list p {
  font-size: 14px;
  color: black !important;
}

.bg-gradient-one {
  background: #e98600 !important;
}

.bg-aqua {
  background-color: #d33105 !important;
}

.bg-sky {
  background-color: #32b8cb !important;
}

.navy {
  background: #286090 !important;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-2gftvx.Mui-selected {
  background-color: #4652c4 !important;
  color: #fff !important;
  font-weight: 400;
}

.MuiPagination-ul li {
  margin: 0px 3px;
}

.MuiPaginationItem-rounded.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 50px !important;
  min-width: 32px !important;
  height: 32px !important;
}

.upload-images {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.btn-outline-primary {
  border: 1px solid #fec52e !important;
  border-radius: 4px;
  color: black !important;
}

.btn-outline-primary:hover {
  background-color: #fec52e !important;
  color: black !important;
}

.nav-icon {
  height: 19px;
  width: 19px;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #fec52e !important;
}

.animation-bg-image {
  /* background-image: url('./../images/AutoGleam-Logo.png'); */
  background-size: cover;
  /* Ensures the image covers the entire element */
  animation: slide 10s infinite alternate;
  /* Define the animation */
}

@keyframes slide {
  0% {
    background-position: 0% 0%;
    /* Start position */
  }

  100% {
    background-position: 100% 0%;
    /* End position */
  }
}

.height_50 {
  height: 50px !important;
}

.height_30 {
  height: 30px !important;
}

.container_checkbox {
  height: 15px !important;
  width: 15px !important;
}

.pie-charts-one {
  height: 220px !important;
  width: 220px !important;
}

.pie-charts {
  height: 230px !important;
  width: 230px !important;
}

.header-logo {
  max-width: 270px !important;
  height: 47px !important;
}

.table td,
.table th {
  font-size: 0.8125rem;
  white-space: nowrap;
}

.not-found-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  color: #343a40;
}
.title {
  font-size: 4rem;
  margin: 0px;
}

.message {
  font-size: 1.5rem;
  margin: 20px 0px;
}
.link {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.coupon-profile-image {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

/* drop down container */
.dropdown-container {
  position: relative;
  width: 200px;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
}

.selected-option {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 2px;
  padding: 2px 5px;
  margin: 2px;
}

.remove-option {
  margin-left: 5px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item.selected {
  background-color: #f0f0f0;
}

.dropdown-item:hover {
  background-color: #e0e0e0;
}

/* CustomSelectDropDown.css */

.search-container {
  position: relative;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  margin-top: 5px !important;
}

.form-control {
  padding-right: 2.5rem; /* Ensure there's space for the clear button */
}

.clear-btn {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #000; /* Light grey color */
}

.clear-btn:hover {
  color: #000; /* Darker color on hover */
}

.form-select {
  width: 100%;
}

.form-label {
  margin-bottom: 0.5rem;
}

.email-footer-bg {
  background-color: #ededed5c;
}

.MuiStepLabel-iconContainer .Mui-active .MuiSvgIcon-root .Mui-active {
  color: #4652c4 !important;
}

.MuiStepIcon-root.Mui-active {
  color: #4652c4 !important;
}

.MuiStepIcon-text .Mui-active {
  color: #4652c4 !important;
}

/* .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color: #fec52e !important;
} */

.MuiStepIcon-root.Mui-completed {
  color: #4652c4 !important;
}

.stepper-button button.MuiButton-colorPrimary.MuiButton-containedPrimary {
  background-color: #4652c4 !important;
  color: #fff !important;
}

.stepper-button button.MuiButton-colorPrimary {
  background-color: white !important;
  color: black !important;
  border: 1px solid #4652c4 !important;
  margin-right: 10px !important;
}

.stepper-button button .MuiButton-colorPrimary {
  color: #000 !important;
}

.MuiButton-colorPrimary.css-117w1su-MuiStepIcon-text
  .css-117w1su-MuiStepIcon-text {
  color: #000 !important;
}

.MuiButtonBase-root.MuiPaginationItem-root {
  border-radius: 30px !important;
}

.mobile-menu-scroll {
  height: calc(100vh - 28vh) !important;
  overflow-y: auto !important;
}

.invoices {
  background-color: #809e90;
}
.products {
  background-color: #c8b0b0;
}
.users {
  background-color: #91d2e8;
}
.align-items {
  align-items: center !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.modal-header {
  padding: "5px 0px" !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.form-control:focus {
  border: 1px solid#4652c4 !important;
  box-shadow: none !important;
}
.box-shadow {
  box-shadow: 0px 0px 8px #e0e0ff !important;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.accordion-header button {
  background-color: transparent !important;
}
.accordion-button:focus {
  border-color: transparent !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d63232;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #17b84d;
}


input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.grey-color{
  color: #c7c9cb !important;
}

.blur-background {
  /* filter: blur(5px); */
  transition: all 0.3s ease-in-out;
  position: relative;
}

.blur-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.6);
  z-index: 1;
}


.border-30{
  border-radius: 30px !important;
}
.right-border{
  border:0;
  border-right: 1px solid black !important;
}
.primary-bg{
  background-color: #4652c4 !important;
}
.primary-border{
  border: 1px solid #4652c4 !important;
}
.primary-color{
  color: #4652c4 !important;
}
